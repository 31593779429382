


























import wAxios from '@/plugins/w/axios'
import { mapState } from 'vuex'
import { mapError } from '@/plugins/app@utils/alert/alert'

export default {
	name: 'Test',

	components: {
		'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue'),
		'a-question-choices': () => import('@/plugins/app@components/exam/a-question-choices.vue'),
		'a-question-header': () => import('@/plugins/app@components/exam/a-question-header.vue')
	},

	data() {
		return {
			question: {
				id: null,
				choices: new Array<any>(),
				type: ''
			},
			loading: false,
			count: 0,
			answer: '',
			isMultiselect: false
		}
	},

	computed: {
		...mapState({
			testQuestionsCount: (state: any) => state.test.info.questions_count,
			testCode: (state: any) => state.user.code
		}),
		isSendDisabled() {
			return (typeof this.answer == 'string' && this.answer.trim() == '') ||
						(typeof this.answer == 'object' && !this.answer.length) ||
						this.loading
		}
	},

	async mounted() {
		console.log(this.testCode)
		console.log(this.$store.state['user'].hash)
		try {
			await this.$store.dispatch('test/getInfo', this.testCode)
			await this.getQuestion()
		} catch (err) {
			await this.$wToast.error(err)
		}
	},

	methods: {
		async getQuestion() {
			this.answer = ''
			let response: any
			try {
				this.loading = true
				response = await wAxios.get_auth(
					`student/exams/${this.testCode}/student/questions`,
					{ headers: { 'token': this.$store.state['user'].hash } }
				)
			} catch (err) {
				await this.$wToast.error(err)
				this.loading = false
				return
			}
			this.loading = false

			this.count++
			// dalsia otazka uz nie je alebo sme znova nacitali rovnaku otazku (mocky)
			if (!response.data.data || response.data.data.id == this.question.id) {
				await this.$router.push({ name: 'Results' })
				return
			}
			this.question = response.data.data || {}
			this.isMultiselect = this.question?.type == 'multiselect'
			this.answer = []
		},

		async leave() {
			const leaveAlert = await this.$ionic.alertController.create({
				header: 'Chcete ukončiť test?',
				message: 'V tomto teste už nebudete môcť neskôr pokračovať',
				buttons: [
					{
						text: 'Áno',
						handler: () => {
							this.$store.commit('user/reset')
							this.$store.commit('test/infoReset')
							this.$router.push({ name: 'Profile' })
						}
					},
					{
						text: 'Nie',
						role: 'cancel'
					}
				]
			})
			await leaveAlert.present()
		},

		async sendAnswer() {
			if (!this.answer || (typeof this.answer === 'string' && !this.answer.length)) {
				return
			}

			const body = this._getAnswerRequestBody()
			console.log(this.answer)

			try {
				this.loading = true
				await wAxios.post_auth(
					`student/exams/${this.testCode}/student/questions/${this.question.id}`,
					body,
					{ headers: { 'token': this.$store.state['user'].hash } }
				)
			} catch (err) {
				this.loading = false
				await this.$wToast.error(err)
				if (mapError(err) == 'Exam is already stopped') {
					await this.$router.push({ name: 'Results' })
				}
				return
			}
			this.loading = false
			await this.getQuestion()
		},

		toggle(data: { choice: any; i: number }) {
			const selected = this.question.choices.findIndex((choice) => choice._selected)
			if (!this.isMultiselect && selected !== -1) {
				this.question.choices[selected]._selected = false
				this.$set(this.question.choices, selected, this.question.choices[selected])
				if (selected === data.i) {
					this.answer = ''
					return
				}
			}
			data.choice._selected = !data.choice._selected
			if (this.isMultiselect) {
				if (data.choice._selected) {
					this.answer.push(data.choice.id)
				} else {
					this.answer.splice(this.answer.findIndex((id) => id == data.choice.id), 1)
				}
			} else {
				this.answer = data.choice.id
			}
			this.$set(this.question.choices, data.i, data.choice)
		},

		_getAnswerRequestBody() {
			let body = {}
			switch (this.question.type) {
			case 'field':
				body = { body: this.answer }
				break
			case 'choices':
				body = { 'exam_question_choice_id': +this.answer }
				break
			case 'multiselect':
				body = { 'exam_question_choice_ids': this.answer }
				break
			}
			return body
		}
	}
}
